@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "HKGrotesk";
  src: url("../public/fonts/HKGrotesk/HKGrotesk-Regular.woff");
  font-weight: 400;
}

@font-face {
  font-family: "HKGrotesk";
  src: url("../public/fonts/HKGrotesk/HKGrotesk-Medium.woff");
  font-weight: 500;
}

@font-face {
  font-family: "HKGrotesk";
  src: url("../public/fonts/HKGrotesk/HKGrotesk-SemiBold.woff");
  font-weight: 600;
}

@font-face {
  font-family: "HKGrotesk";
  src: url("../public/fonts/HKGrotesk/HKGrotesk-Bold.woff");
  font-weight: 700;
}

@font-face {
  font-family: "HKGrotesk";
  src: url("../public/fonts/HKGrotesk/HKGrotesk-ExtraBold.woff");
  font-weight: 800;
}

@font-face {
  font-family: "HKGrotesk";
  src: url("../public/fonts/HKGrotesk/HKGrotesk-Black.woff");
  font-weight: 900;
}

@layer components {
  .button,
  .input-button + label {
    @apply flex font-mono transition-all duration-75 px-4 py-2 rounded border-solid border-2 border-black bg-white shadow-mimic-1 justify-center items-center hover:bg-slate-100 cursor-pointer select-none;
  }

  .input-button {
    @apply hidden;
  }

  .button {
    @apply active:bg-slate-200 active:translate-y-mimic-active active:-translate-x-mimic-active active:shadow-none;
  }

  .input-button:checked + label {
    @apply bg-slate-200 translate-y-mimic-active -translate-x-mimic-active shadow-none;
  }

  .timer-text-shadow {
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white,
      -4px 4px black;
  }

  .react-draggable:hover {
    filter: drop-shadow(rgba(61, 61, 255, 0.35) -1px 1px 1px);
  }

  .react-draggable.react-draggable-dragging {
    filter: drop-shadow(rgba(61, 61, 255, 0.5) -1px 2px 2px);
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
